import { Grommet, DataTable, Text, Box, Anchor } from 'grommet';
import React, { useState, useEffect } from 'react';

const theme = {
  global: {
      font: {
	            family: 'monospace',
	            size: '18px',
	            height: '20px',
          },
    },
};

function App() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [select, setSelect] = useState([]);
  const [awsClaim, setAwsClaim] = useState("Data in this table is copyright AWS or its affiliates.");
  const [awsDisclaimer, setAwsDisclaimer] = useState("This data may be out of date, see amazon for the most accurate information.");
  const [awsDate, setAwsDate] = useState("");
  var awsServiceListUrl = "https://api.regional-table.region-services.aws.a2z.com/index.json";
  // var awsServiceListUrl = "/aws.json";
  useEffect(() => {
    fetch(awsServiceListUrl, {cache: 'default'})
	.then(res => res.json())
	.then((result) => {
	  let regionSet = new Set();
          let regions = [];
          let services = {}
          let serviceRows = [];
	  setAwsClaim(result.metadata.copyright);
	  setAwsDisclaimer(result.metadata.disclaimer);
	  setAwsDate(result.metadata["source:version"]);
	  result.prices.forEach(px => {
	      var region = px.attributes["aws:region"];
	      var svc = px.attributes["aws:serviceName"];
	      regionSet.add(region);
	      if(svc in services === false) {
		services[svc] = {"service": svc};
	      }
	      services[svc][region] = true;
	  });
	  regionSet.forEach( (r) => { regions.push(r); });
	  regions.sort();
	  var cols = [ {
		  property: "service",
		  header: <Box>Service Name</Box>,
		  // pin: true,
		  primary: true, }, ];

	  for(let r of regions) {
	    cols.push({
	      property: r,
	      header: <Box><nobr>{r}</nobr></Box>,
	      primary: false,
              align: "center",
              render: datum => (<Text>{datum[r] ? "✅": "❌"}</Text>),
	    });
	  }
	  setColumns(cols);
	  for(let s in services) {
	    serviceRows.push(services[s]);
	  }
	  serviceRows.sort(function(a,b){
		  if (a.service == b.service) {
			  return 0;
		  }
		  if (a.service < b.service) {
			return -1;
		  }
		  return 1;
	  });
	  return [cols, serviceRows];
	})
	.then((result) => {
	  setColumns(result[0]);
	  setData(result[1]);
	})
	.catch(error => {
		console.error("Caught error", error);
		setError(error);
	})
	  .finally(()=>{
	    setLoading(false);
	  });
  },[]);
  if(loading){
    return <Text>Loading...</Text>;
  }
  if(error) {
    return <Text>Error: {error}</Text>;
  }
  return (
    <Grommet theme={theme}>
      <header className="App-header"></header>
        <DataTable
	  columns={ columns }
	  // pin="header"
	  data={data}
	  select={select}
	  onSelect={setSelect}
       >
       </DataTable>
       <Box 
	  direction="column"
	  border={{ color: 'brand', size: 'large' }}
	  pad="medium" >
         <Text>Inspired by <Anchor href="https://instances.vantage.sh/">ec2instances.info</Anchor></Text><br />
         <Text>This data was pulled from AWS just now by your browser, but was generated by AWS on {awsDate}</Text><br />
         <Text>No claims are made regarding the accuracy of this table or its fitness for any particular use.</Text>
       </Box>
       <Box 
	  direction="column"
	  border={{ color: '#FF9900', size: 'large' }}
	  pad="medium" >
         <Text>This site is not in any way affiliated with AWS or its affiliates.</Text>
         <Text>The data used by this website is subject to the following notices:</Text>
	 <Box>{awsClaim}</Box>
	 <Box>{awsDisclaimer}</Box>
       </Box>
    </Grommet>
  );
}

export default App;
